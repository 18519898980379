import Head from 'next/head'

import baseConfig from '@baseConfig'
import useGlossarySSR from '@hooks/useGlossarySSR'
import useDate from '@hooks/useDate'
import { stringToURL } from '@helpers/urlFormatter'
import { createApolloClient } from '@lib/apolloClient'
import { getCookieAgentSSR } from '@utils/getCookieAgent'
import { getPublicationService } from '@services/publication/getPublication'
import { agent as GET_AGENT } from '@graphql/session.graphql'
import { getEventService } from '@features/App/services/event/getEvent'
import { LayoutSSR } from '@features/Layout/LayoutSSR'
import Login from '@features/Login/Components/Login'

const LoginView = ({ initialData, locale }) => {

  if (!initialData) return <Login />

  const { glossarySSR } = useGlossarySSR(locale)
  const { format } = useDate()

  const event = initialData.event
  const box = initialData.publication?.box
  const timezone = initialData.event.forum.region.timeZone

  const replacer = {
    EVENT: event.name,
    DATE: format(event.date, 'L', locale, timezone),
    VENUE: event.forum.name,
    TYPE: box ? box.type.name : glossarySSR('Tickets'),
    SECTION: box ? box.section?.name ?? box.forumSectionNomap : '',
    PERFORMER: event?.performers?.length > 0 ?
      ` con ${event.performers.map(p => p.name).join(', ')}`
      : '',
  }

  const metaDescription = box ?
    glossarySSR('MetaDescriptionBoxEvent', replacer)
    : glossarySSR('MetaDescriptionBoxEventNoSection', replacer)

  const metaTitle = box ?
    glossarySSR('MetaTitleBoxEvent', replacer)
    : glossarySSR('MetaTitleBoxEventNoSection', replacer)

  const urlData = box ?
    `${stringToURL(event.forum.name)}-${stringToURL(box.section?.name ?? box.forumSectionNomap)}`
    : `${stringToURL(event.forum.name)}`

  const imageUrl = box ?
    `${baseConfig.previewRoot}/${box.pictures.find(p => p.cover)?.file?.id ?? ''}`
    : (
      event?.files?.length > 0 ?
        `${baseConfig.previewRoot}/${initialData.event.files[0].id}`
        : 'https://stadibox.com/images/event-og-image.jpg'
    )

  return <>
    <Head>
      <title>{metaTitle}</title>
      <meta name='description' content={metaDescription} />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:type' content='website' />
      {box && <meta
        property='og:url'
        content={`${baseConfig.rootDomain}/event/box/${urlData}/${initialData.publication.id}`}
      />
      }
      <meta property='og:image' content={`${imageUrl}`} />
    </Head>
    <Login />
    {box ?
      box.pictures.map(pic => (<img
        style={{ width: '0px', height: '0px' }}
        src={`${baseConfig.cdnRoot}/${pic.file.url}`}
        alt={metaDescription}
      />))
      : <img
        style={{ width: '0px', height: '0px' }}
        src={imageUrl}
        alt={metaDescription}
      />
    }
    <h1 style={{ visibility: 'hidden', height: 0, margin: 0, padding: 0 }}>{event.name}</h1>
  </>
}

LoginView.getLayout = function getLayout(page) {
  return <LayoutSSR hideSearch addMetadata={false}>{page}</LayoutSSR>
}

export async function getServerSideProps(context) {
  const { query, req, res, locale, resolvedUrl } = context
  const currentPath = resolvedUrl

  try {
    const { redirect, event } = query

    if (!redirect) return { props: { locale } }

    const agentId = getCookieAgentSSR({ req, res })
    const apolloClient = createApolloClient(agentId)

    const options = {
      context: req
        ? {
          headers: {
            'x-ssr-agent': agentId,
            'x-ssr-real-ip': req.headers['x-real-ip'],
            'x-ssr-user-agent': req.headers['user-agent'],
          },
        }
        : {},
    }

    // Verify if there's already a session
    const respAgent = await apolloClient.query({
      query: GET_AGENT,
      fetchPolicy: 'no-cache',
      context: {
        ssr: true,
        headers: {
          'x-ssr-agent': agentId,
          'x-ssr-real-ip': req.headers['x-real-ip'],
          'x-ssr-user-agent': req.headers['user-agent'],
        },
      },
    })
    if (respAgent?.data?.agent?.session) {
      console.log(`[LOGIN] Redirected to login, but already has session. Agent: ${JSON.stringify(respAgent?.data?.agent)}`)
    }

    // Verify if we were redirected from the checkout for boxes
    const checkoutBoxRegex = /^\/checkout\/box\//g
    if (redirect.match(checkoutBoxRegex)) {
      const _redirect = redirect.split('?')[0].split('/')
      if (_redirect.length === 5) {
        const boxEventId = _redirect[4]
        const respPublication = await getPublicationService(apolloClient, {
          id: boxEventId,
          options,
        })
        return {
          props: {
            initialData: {
              publication: respPublication.boxEventId,
              event: respPublication?.boxEventId?.event ?? null,
            },
            locale,
          }
        }
      }
    }

    // Verify if we were redirected from the checkout for tickets
    const checkoutTicketRegex = /^\/checkout\/ticket\//g
    if (redirect.match(checkoutTicketRegex)) {
      const eventData = await getEventService(apolloClient, {
        variables: {
          id: event,
          includeMap: false,
        },
        options,
      })
      return { props: { initialData: { event: eventData?.event ?? null }, locale } }
    }

    return { props: { locale } }

  } catch (error) {
    console.log(error)
    return {
      redirect: {
        destination: `/503?from=${encodeURIComponent(currentPath)}`,
        permanent: false,
      },
    }
  }
}

export default LoginView
