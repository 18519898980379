import { useCallback, useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Checkbox, Link, CircularProgress } from '@mui/material'
import * as yup from 'yup'
import { useFormik } from 'formik'

import baseConfig from '@baseConfig'
import { useError, useGlossary } from '@features/App/hooks'
import { CustomTextField, PhoneField } from '@features/App/Components/Forms'
import { GreenButton } from '@features/App/Components'
import CustomDatePicker from '@features/App/Components/Forms/CustomDatePicker'
import { useSignUp } from '../hooks'
import PasswordFieldEditor from '@features/App/Components/Password/PasswordFieldEditor'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]:{
    backgroundColor: theme.palette.gray[100],
  },
}))

const MainForm = styled(props => <Box {...props}/>)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
      'name lastName'
      'email email'
      'phone phone'
      'date date'
      'pass pass'
      'checkTerms checkTerms'
      'palcos palcos'
      'checkPalcos checkPalcos'
      'captcha captcha'
      ' button button'
    `,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '2fr 2fr',
    gridTemplateAreas: `
      'name lastName'
      'email phone'
      'date pass'
      'palcos palcos'
      'checkPalcos checkTerms'
      'captcha captcha'
      'button button'
    `,
  },
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '600',
}))

const Checklabel = styled(Typography)(({ theme }) => ({
  font: theme.font.roboto.paragraph.minimum,
  fontWeight: '400',
  padding: '0',
}))

const CheckContainer = styled(Box)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'flex-start', 
  alignItems: 'center',
  width: '100%',
  borderRadius: '4px',
  backgroundColor: theme.palette.gray[100],
  [theme.breakpoints.up('md')]:{
    backgroundColor: theme.palette.gray[100],
  },
}))

const ButtonCont = styled(Box)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  marginTop: theme.spacing(3),
}))

const ButtonLabel = styled(Box)(({ theme }) => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  padding: theme.spacing(1),
  font: theme.font.roboto.paragraph.small,
}))

const BoxCaptcha = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const RegisterForm = ({  ...props }) => {

  const { glossary } = useGlossary()
  const { showAlert, showSuccess } = useError()
  const { userSignUp, loading } = useSignUp()

  const [terms, setCheckTerms] = useState(false)
  const [owner, setCheckOwner] = useState(false)
  const [gre, setGre] = useState(null)
  const recaptchaRef = useRef()
  const [Recaptcha, setRecaptcha] = useState()

  const validationSchema = yup.object({
    name: yup.string()
      .required(),
    lastName: yup.string()
      .required(),
    email: yup.string()
      .email()
      .required(),    
    password: yup.string()
      .required(), 
    phone: yup.string()
      .required(),
    birth: yup.date()
      .required(),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      email: '',
      password: '', 
      phone: '',
      birth: null,
    },
    validationSchema,
    // eslint-disable-next-line consistent-return
    onSubmit: async () => {
      try {
        handleSignUp()
      } catch (error) {
        logError(error)
      }
    },
  })

  const handleTermsCheck = event => {
    setCheckTerms(event.target.checked)
  }

  const handleOwnerCheck = event => {
    setCheckOwner(event.target.checked)
  }

  const handleSignUp = useCallback(() => {
    const signUpData = {
      ...formik.values,
      gre: gre || false,
      agreeTerms: terms || false,
      owner: owner || false,
      phone: formik.values.phone.replaceAll(' ', ''),
    }
  
    if (terms && gre) {
      userSignUp(signUpData)
        .then(response => {
          if (response.signUp.status === 1) {
            //Email exists
            showAlert(glossary('EmailExist'), 'error')
          } else if ( response.signUp.status === 2) {
            // Captcha Error
            showAlert(glossary('Session2'), 'error')
          } else if ( response.signUp.status === 3 && response.signUp.session) {
            // Successful sign-up
            showSuccess(glossary('Success'))
          } else {
            // Not session error
            showAlert(glossary('SessionError'), 'error')
          }
        })
        .catch(error => {
          showAlert(glossary('ErrorSignUp'), 'error')
          console.error('SignUp Error:', error)
        })
    } else {
      showAlert(glossary('AceptCaptcha'), 'error')
    }
  }, [formik.values, terms, gre, userSignUp, owner, glossary])

  useEffect(() => {
    const loadRecaptcha = async() => {
      setRecaptcha((await import('react-google-recaptcha')).default)
    }
    loadRecaptcha()
      .catch(() => {
        enqueueSnackbar('Error al cargar recaptcha', { variant: 'error' })
        console.error(error)
      })
    const loadFacebookLogin = async() => {

    }
    loadRecaptcha()
      .catch(() => {
        enqueueSnackbar('Facebook Login no disponible', { variant: 'warning' })
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (formik.touched.email && Boolean(formik.errors.email) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.email}`, 'error')
    if (formik.touched.password && Boolean(formik.errors.password) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.password}`, 'error')
    if (formik.touched.name && Boolean(formik.errors.name) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.name}`, 'error')
    if (formik.touched.lastName && Boolean(formik.errors.lastName) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.lastName}`, 'error')
    if (formik.touched.phone && Boolean(formik.errors.phone) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.phone}`, 'error')
    if (formik.touched.birth && Boolean(formik.errors.birth) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.birth}`, 'error')
  }, [formik, formik.errors])

  return <Root>
    <MainForm component='form' onSubmit={formik.handleSubmit}>
      <Container sx= {{ gridArea: 'name' }}>
        <Label>
          { glossary('Name') }
        </Label>
        <CustomTextField
          onChange={e => formik.setFieldValue('name', e.target.value)}
          name='name'
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
      </Container>
      <Container sx= {{ gridArea:'lastName' }}>
        <Label>
          { glossary('LastName')}
        </Label>
        <CustomTextField
          onChange={e => formik.setFieldValue('lastName', e.target.value)}
          name='LastName'
          value={formik.values.lastName}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        />
      </Container>
      <Container sx= {{ gridArea:'email' }}>
        <Label>
          { glossary('Email')}
        </Label>
        <CustomTextField
          onChange={e => formik.setFieldValue('email', e.target.value)}
          name='email'
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
      </Container>
      
      <Container sx= {{ gridArea: 'pass' }}>
        <Label>
          {glossary('Passw')}
        </Label>
        <PasswordFieldEditor
           onChange={e => formik.setFieldValue('password', e.password)}
          name='password'
          showSugg={false}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
        />
      </Container>
      <Container sx= {{ gridArea: 'phone' }}>
        <PhoneField
          initialLada={'52'}
          initialPhone={formik.values.phone}
          phoneOnChange={(newLada, newValue) =>
            formik.setFieldValue('phone', `${newLada ?? ''}${newValue ?? ''}`)
          }
          error={formik.touched.phone && Boolean(formik.errors.phone)}
        />
      </Container>
      <Container sx= {{ gridArea: 'date' }}>
        <Label>
          { glossary('Birthdate')}
        </Label>
        <CustomDatePicker
          onChange={e => formik.setFieldValue('birth', e)}
          name='birthDate'
          value={formik.values.birth}
          error={formik.touched.birth && Boolean(formik.errors.birth)}
          type= 'date'
        />
      </Container>
      <BoxCaptcha sx = {{ gridArea: 'captcha' }}>
        {
          Recaptcha &&
          <Recaptcha hl={'es'} ref={recaptchaRef} size={'normal'}
            sitekey={baseConfig.recaptchaSiteKey}
            onChange={gre => setGre(gre)}
            onExpired={() => setGre(null)}/>
        }
      </BoxCaptcha>
      <Label sx={{ gridArea:'palcos' }}>
        {glossary('Have')}
      </Label>
      <CheckContainer sx={{ gridArea:'checkPalcos' }}>
        <Checkbox
          checked={owner}
          onChange={handleOwnerCheck}
          value='prop'
          color= 'secondary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        />
        <Checklabel>
          {glossary('Own')}
        </Checklabel>
      </CheckContainer>
      <CheckContainer sx={{ gridArea:'checkTerms' }}>
        <Checkbox
          required
          checked={terms}
          onChange={handleTermsCheck}
          value='terms'
          color='secondary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        />
        <Checklabel>{glossary('AceptTerms') + ' '}<Link href={'#'}
          // eslint-disable-next-line max-len
          onClick={() => window.open('https://cdn.stadibox.com/m4zVSTyWZZ/TERMINOS%20Y%20CONDICIONES%20STADIUM%20SUITES%2019.06.23.pdf', '_blank')}
          style={{ textDecoration: 'underline' }}
          color={'secondary'}>{ glossary('TAC')}</Link> 
        </Checklabel> 
      </CheckContainer>
      <ButtonCont sx={{ gridArea: 'button' }}>
        <GreenButton 
          type='submit' 
          sx= {{ width: '50%' }}
          disabled={loading}
        >
          {
            loading ? 
              <CircularProgress sx={{ width: '21px !important', height: '21px !important' }}/>        
              : <ButtonLabel>
                  {glossary('RegisterNow')}
                </ButtonLabel>
          }
        </GreenButton>
      </ButtonCont>
    </MainForm>

  </Root>
}


export default RegisterForm
