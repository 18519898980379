'use client'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import { useGlossary } from '@features/App/hooks'
import { CustomTab as Tab, CustomTabs as Tabs } from '@features/App/Components/Tabs'
import { CustomSwipeableViews } from '@features/App/Components'
import FormLogin from './FormLogin'
import RegisterForm from './RegisterForm'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 'min(700px, 100%)',
    backgroundColor: theme.palette.gray[100],
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows.elevation.large,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(20),
  },
}))

const TabsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[100],
  width: '100%',
}))

const ContainerLog = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]:{
    padding: `${theme.spacing(5)} ${theme.spacing(20)}`,
  },
}))

const ContainerReg = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  [theme.breakpoints.up('md')]:{
    padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
  },
}))

const StyledTab = styled(props => <Tab {...props}/>)(({ theme }) => ({
  width: '50%',
}))

const TitleLabel = styled(props => <Box {...props}/>)(({ theme }) => ({
  font: theme.font.roboto.paragraph.large,
  fontWeight: '700',
}))

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  justifyContent:'center',
  [theme.breakpoints.up('md')]:{
    marginBottom: theme.spacing(1),
  },
}))

const Login = ({}) => {

  const { glossary } = useGlossary()

  const [tabView, setTabView] = useState(0)

  const handleChange = (event, newValue) => {
    setTabView(newValue)
  }

  return <Root>
    <HeaderContainer>
      <TitleLabel>
        { glossary('Identify') }
      </TitleLabel>
    </HeaderContainer>
    <TabsContainer>
      <Tabs
        tabView={tabView}
        handleChange= { handleChange}
      >
        <StyledTab label={ glossary('Login') } />
        <StyledTab label={ glossary('Register') } />
      </Tabs>
    </TabsContainer>
    <CustomSwipeableViews
      index={tabView}
      onChangeIndex={i => setTabView(i)}
      disabled = {true}
    >
      <ContainerLog>
        <FormLogin/>
      </ContainerLog>
      <ContainerReg>
        <RegisterForm/>
      </ContainerReg>
    </CustomSwipeableViews>
  </Root>

}

export default Login
