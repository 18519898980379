import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'

import useTheme from '@hooks/useTheme'
import Nav from './Nav'
import Footer from './Footer'
import BottomMenu from './BottomMenu'

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
    '& *::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
  },
}))

const ViewContainer = styled(Box, {
  shouldForwardProp: propName => (
    propName !== 'hideSearch'
  ),
})(({ theme, hideSearch }) => ({
  maxHeight: `calc(100vh - ${theme.spacing(hideSearch ? 8 : 9)})`,
  minHeight: `calc(100vh - ${theme.spacing(hideSearch ? 8 : 9)})`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.gray[200],
  overflowX: 'hidden',
  overflowY: 'scroll',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flex: '1',
  },
}))

export const LayoutSSR = ({ children, hideFooter = false, hideSearch = false, showMenu = false, ...props }) => {

  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <MainContainer>
      <Nav hideSearch={hideSearch}/>
      {/* <TawkMessengerReact */}
      {/*  propertyId='5990dc5f1b1bed47ceb04669' */}
      {/*  widgetId='default'/> */}
      <ViewContainer hideSearch={!isMd && hideSearch}>
        {children}
        {isMd && !hideFooter && <Footer/>}
        {!isMd && showMenu && <BottomMenu/>}
      </ViewContainer>
    </MainContainer>
  )
}
