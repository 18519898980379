import { styled } from '@mui/material/styles'
import { Tabs } from '@mui/material'

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))(
  ({ theme }) => ({
    height: '32px',
    maxHeight: '32px',
    minHeight: '32px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.gray[300],

    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    
    '& .MuiTabScrollButton-root': {
      width: '20px',
      opacity: 0,
      position: 'absolute',
      height: '32px',
      zIndex: 3,
      transition: 'all 0.2s ease-out',
      backgroundColor: theme.palette.background[70],
      '& svg path': {
        fill: theme.palette.primary.main,
      },
      '&:last-of-type': {
        right: 0,
      },
    },
    '&:hover .MuiTabScrollButton-root': {
      opacity: 0,
      '&.Mui-disabled': {
        opacity: 0,
      },
    },
  })
)

const CustomTabs = ({ tabView=0, handleChange, children, ...props }) => {

  return <StyledTabs
    value={tabView}
    onChange={handleChange}
    variant='scrollable'
    scrollButtons={true}
    allowScrollButtonsMobile={false}
    selectionFollowsFocus
  >
    { children }
  </StyledTabs>
}

export default CustomTabs
