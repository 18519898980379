/* eslint-disable max-len */
import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, CircularProgress, Link } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import * as yup from 'yup'
import { useFormik } from 'formik'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import baseConfig from '@baseConfig'
import useError from '@hooks/useError'
import useGlossary from '@hooks/useGlossary'
import useSession from '@hooks/useSession'
import CustomTextField from '@components/Forms/CustomTextField'
import GreenButton from '@components/GreenButton'
import FacebookIcon from '@components/Icons/FacebookIcon'
import GoogleIcon from '@components/Icons/GoogleIcon'
import useAuth from '../hooks/useAuth'
import RecoverModal from './RecoverModal'
import PasswordField from '@features/App/Components/Password/PasswordField'

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray[200],
  width: '100%',
  height: '100%',
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.gray[100],
  },
}))

const MainForm = styled(props => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: 600,
}))

const ButtonLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  font: theme.font.roboto.paragraph.small,
}))

const FbButton = styled(props => <Button {...props} />)(({ theme }) => ({
  textTransform: 'none',
  marginBottom: '15px',
  fontWeight: 700,
  justifyContent: 'left',
  paddingLeft: '28px',
  color: '#FFF',
  background: '#1877F2',
  fontSize: 14,
  height: '42px',
  '&:hover': {
    backgroundColor: 'rgba(59,89,152,.85)',
  },
  '& svg path': {
    fill: theme.palette.gray[200],
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    paddingLeft: '0px',
  },
}))

const BoxB = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(.5),
  marginBottom: theme.spacing(2),
}))

const GoogleButton = styled(props => <Button {...props} />)(({ theme }) => ({
  textTransform: 'none',
  marginBottom: '15px',
  fontWeight: 700,
  justifyContent: 'left',
  paddingLeft: '28px',
  color: '#595C5F',
  background: theme.palette.primary.contrastText,
  fontSize: 14,
  height: '42px',
  border: `1px solid ${theme.palette.gray[300]}`,
  '&:hover': {
    backgroundColor: 'rgba(213, 219, 219)',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    paddingLeft: '0px',
  },
}))

const CustomLink = styled(props => <Link {...props} />)(({ theme }) => ({
  alignSelf: 'flex-end',
  font: theme.font.roboto.paragraph.small,
  color: theme.palette.gray[600],
}))

const FormLogin = ({ onLoging }) => {

  const { glossary } = useGlossary()
  const { showAlert, logError } = useError()
  const { userLogin, googleUserLogIn, fbUserLogin, googleNativeUserLogIn, loading } = useAuth()
  const { session } = useSession()

  // const [loading, setLoading] = useState(false)
  const [recoverModalOpen, setRecoverModalOpen] = useState(false)
  const [oneSignalData, setOneSignalData] = useState({})

  const validationSchema = yup.object({
    email: yup.string()
      .email()
      .required(`"${glossary('Email')}" ${glossary('MadatoryField')}`),
    password: yup.string()
      .required(`"${glossary('Passw')}" ${glossary('MadatoryField')}`),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    // eslint-disable-next-line consistent-return
    onSubmit: async () => {
      try {
        handleLogin()
      } catch (error) {
        logError(error)
      }
    },
  })

  const handleLogin = useCallback(() => {
    if (session?.id) return showAlert(glossary('AlreadyHasSession'), 'warning')
    userLogin(formik.values)
      .then(resp => {
        if (!resp.login) return showAlert(glossary('IncorrectLoginData'), 'error')
      })
      .catch(err => console.error(err))
  }, [formik.values, loading, session])

  const responseGoogle = useCallback(params => {
    googleUserLogIn({ accessToken: params.access_token })
  }, [])

  useEffect(() => {
    const isGoNative = navigator.userAgent.indexOf("gonative") >= 0
    if (isGoNative) {
      // Remove browser-only buttons.
      const elements = document.querySelectorAll(".browser-only")
      if (elements) elements.forEach((element) => element.remove())
      updateDataOneSignal()
    } else {
      // Remove native-only buttons.
      const elements = document.querySelectorAll(".native-only")
      if (elements) elements.forEach((element) => element.remove())
    }
  }, [])

  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      responseGoogle(tokenResponse)
    },
    onError: errorResponse => {
      console.log('Login Failed. Error:', errorResponse)
    },
  })

  const updateDataOneSignal = useCallback(() => {
    gonative.onesignal.onesignalInfo().then(function (oneSignalInfo) {
      setOneSignalData(oneSignalInfo)
      // alert(JSON.stringify(oneSignalInfo))
    })
  }, [oneSignalData])

  const responseGoogleNative = async (response) => {
    try {
      const responseFetch = await window.fetch(
        `https://oauth2.googleapis.com/tokeninfo?id_token=${response.idToken}`
      )
      const payload = await responseFetch.json()

      if (!payload.error) {
        googleNativeUserLogIn({ ...payload, oneSignalData })
          .then((data) => {
            // alert('then', data.gNativeLogin)
            if (data.gNativeLogin) {
              setLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)
            enqueueSnackbar(error.toString(), { variant: "error" })
          })
      } else {
        // alert(JSON.stringify(response))
        // alert(payload.error_description)
      }
    } catch (error) {
      enqueueSnackbar(localeContext.glossary["Session4"], { variant: "error" })
    }
  }

  const responseFacebook = useCallback(async (response) => {
    const isGoNative = navigator.userAgent.indexOf("gonative") >= 0
    let _response = response
    if (isGoNative) {
      _response.expiresIn = null
      _response.userID = response.userId
    }
    setLoading(true)
    if (response.status === "unknown") {
      setLoading(false)
      return
    }
    fbUserLogin({ ..._response, oneSignalUserId: oneSignalData.oneSignalUserId })
      .then((data) => {
        if (data.fbLogin) {
          setLoading(false)
        } else {
          // TODO: TRADUCIR
          showAlert("Facebook no proporcionó tu email, así que no fue posible iniciar sesión. Por favor, elige otro método de inicio de sesión.", 'error')
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [oneSignalData])

  useEffect(() => {
    if (formik.touched.email && Boolean(formik.errors.email) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.email}`, 'error')
    if (formik.touched.password && Boolean(formik.errors.password) && formik.isValidating && formik.isSubmitting)
      showAlert(`${formik.errors.password}`, 'error')
  }, [formik, formik.errors])

  return <Root>
    <BoxB className='browser-only'>
      <FacebookLogin
        appId={baseConfig.fbAppId}
        disableMobileRedirect={true}
        autoLoad={false}
        fields='name,email,picture'
        render={renderProps => (
          <FbButton fullWidth={true} disableElevation
            onClick={renderProps.onClick}
            startIcon={<FacebookIcon style={{ fontSize: '24px' }} />}>{glossary('FbLogin')}</FbButton>)}
            callback={response => {
              if (response.status === 'unknown') {
                return
              }
              fbUserLogin(response)
            }} />

      <GoogleButton onClick={() => googleLogin()}
        startIcon={<GoogleIcon style={{ fontSize: '5px' }} />}>
        {glossary('GLogin')}
      </GoogleButton>
    </BoxB>
    <BoxB className='native-only'>
      <FacebookLogin
        appId={baseConfig.fbAppId}
        disableMobileRedirect={true}
        autoLoad={false}
        fields='name,email,picture'
        render={renderProps => (
          <FbButton fullWidth={true} disableElevation
            onClick={(e) =>
              gonative.socialLogin.facebook.login({
                callback: responseFacebook,
                scope: "public_profile, email",
              })
            }
            startIcon={<FacebookIcon style={{ fontSize: '24px' }} />}>{glossary('FbLogin')}</FbButton>)}
        callback={response => {
          if (response.status === 'unknown') {
            return
          }
          fbUserLogin(response)
        }} />

      <GoogleButton
        onClick={(e) =>
          gonative.socialLogin.google.login({
            callback: responseGoogleNative,
          })
        }
        startIcon={<GoogleIcon style={{ fontSize: '5px' }} />}>
        {glossary('GLogin')}
      </GoogleButton>
    </BoxB>
    <MainForm component='form' onSubmit={formik.handleSubmit}>
      <Container>
        <Label>
          {glossary('Email')}
        </Label>
        <CustomTextField
          onChange={e => formik.setFieldValue('email', e.target.value)}
          name='email'
          value={formik.values.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
      </Container>
      <Container>
        <Label>
          {glossary('Passw')}
        </Label>
        <PasswordField
          onChange={e => formik.setFieldValue('password', e)}
          name='password'
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          type='password'
        />
      </Container>
      <CustomLink underline='hover' onClick={e => setRecoverModalOpen(true)}>
        {glossary('ForgotPassword')}
      </CustomLink>
      <GreenButton
        type='submit'
        disabled={loading}
      >
        {
          loading ?
          <CircularProgress sx={{ width: '21px !important', height: '21px !important' }}/>
          : <ButtonLabel>
              {glossary('Login')}
            </ButtonLabel>
        }
      </GreenButton>
    </MainForm>
    <RecoverModal open={recoverModalOpen} setOpen={setRecoverModalOpen} />
  </Root>
}

export default FormLogin
