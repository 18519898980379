import { styled } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'

const StyledDatePicker = styled(props => <DatePicker {...props} />)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  font: theme.font.roboto.paragraph.medium,
  fontWeight: 500,
  borderRadius: theme.spacing(0.5),
  transition: 'all 0.3s ease-in-out',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: theme.spacing(5),
  },
  // '& .MuiInputAdornment-positionEnd': {
  //   display: 'none',
  // },
  '& input': {
    font: theme.font.roboto.paragraph.medium,
    fontWeight: 500,
    '&.Mui-disabled': {
      '-webkit-text-fill-color': `${theme.palette.gray.main} !important`,
      opacity: 0.38,
      // textTransform: 'capitalize',
    },
  },
  '& fieldset': {
    border: `1px solid ${theme.palette.gray[300]}`,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.gray[100],
    color: `${theme.palette.gray.main} !important`,
    transition: 'all 0.3s ease-in-out',
  },
  '& .MuiFormHelperText-root': {
    display: 'none',
  },
  '&.editing > .MuiInputBase-root': {
    backgroundColor: `${theme.palette.background.dark}40`,
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.dark,
    },
    '&.Mui-error': {
      border: `solid 1px ${theme.palette.semantic.danger}`,
    },
  },
}))

const CustomDatePicker = ({ ...props }) => {
  return <StyledDatePicker {...props} />
}

export default CustomDatePicker
