import {useEffect, useRef, useState} from 'react'
import { styled } from '@mui/material/styles'
import { Box, Dialog, DialogContent } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import useError from '@hooks/useError'
import useGlossary from '@hooks/useGlossary'
import GreenButton from '@components/GreenButton'
import CustomTextField from '@components/Forms/CustomTextField'
import baseConfig from '@baseConfig'
import useAuth from '../hooks/useAuth'

const CustomDialogContent = styled(props => <DialogContent {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}))

const CustomDialog = styled(props => <Dialog {...props}/>)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    '& .MuiDialog-paper': {
      borderRadius: theme.spacing(1),
    },
  },
}))

const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}))

const TitleLabel = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.subtitle.medium,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}))

const SubtitleLabel = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}))

const Label = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  fontWeight: 600,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
}))

const FormBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
  alignItems: 'flex-start',
  paddingTop: theme.spacing(2),
}))

const StyledGreenButton = styled(props => <GreenButton {...props}/>)(({ theme }) => ({
  width: '100%',
  height: '40px',
  [theme.breakpoints.up('md')]:{
    width: '50%',
  },
}))

const RecoverModal = ({ open, setOpen }) => {

  const { glossary } = useGlossary()
  const { sendPasswordRecover } = useAuth()

  const recaptchaRef = useRef()
  const { showAlert, logError } = useError()
  const [gre, setGre] = useState(null)
  const [Recaptcha, setRecaptcha] = useState(null)

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  })

  const handleClose = () => {
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async values => {
      if(!gre) return showAlert(glossary('Captcha'), 'error')
      if(!values.email) return showAlert(glossary('AddEmail'), 'error')

      try {
        const resp = await sendPasswordRecover({email: values.email, gre})
        if (resp.status === 1) {
          showAlert(glossary('NoRegisteredAccount'), 'error')
        } else if (resp.status === 3 && resp.handle) {
          showAlert(glossary('CheckEmail'), 'success')
          setTimeout(() => {
            handleClose()
          }, 500)
        } else {
          showAlert(glossary('ErrorLoading'), 'error')
        }
      } catch(e) {
        logError(e, glossary('ErrorLoading'))
      }
      setGre(null)
      if (recaptchaRef.current) recaptchaRef.current.reset()
    }
  })

  useEffect(() => {
    const loadRecaptcha = async() => {
      setRecaptcha((await import('react-google-recaptcha')).default)
    }
    loadRecaptcha()
      .catch(() => {
        showAlert(glossary('CaptchaError'), 'error')
      })
  }, [])

  return <CustomDialog
    open={open}
    onClose={handleClose}
    sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
  >
    <CustomDialogContent>
      <TitleLabel>{glossary('ForgotPassword?')}</TitleLabel>
      <SubtitleLabel>{glossary('PasswordRecoveryEmail')}</SubtitleLabel>
      <FormBox component={'form'} onSubmit={formik.handleSubmit}>
        <Container>
          <Label>{glossary('Email')}{'*'}</Label>
          <CustomTextField
            onChange={e => formik.setFieldValue('email', e.target.value)}
            name='email'
            fullWidth
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
        </Container>
        { Recaptcha &&
          <Recaptcha hl={'es'} ref={recaptchaRef} size={'normal'}
           sitekey={baseConfig.recaptchaSiteKey}
           onChange={gre => setGre(gre)}
           onExpired={() => setGre(null)}
          />
        }
        <StyledGreenButton type='submit' sx={{ alignSelf: 'flex-end' }}>
          { glossary('Send')}
        </StyledGreenButton>
      </FormBox>
    </CustomDialogContent>
  </CustomDialog>
}

export default RecoverModal
