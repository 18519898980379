import CustomTabs from './CustomTabs'
import CustomTab from './CustomTab'

export default {
  CustomTabs,
  CustomTab,
}
export {
  CustomTabs,
  CustomTab,
}
