import baseConfig from '@baseConfig'
import esGlossary from '@features/Locale/es.json'
import enGlossary from '@features/Locale/en.json'
import ptGlossary from '@features/Locale/pt.json'
import frGlossary from '@features/Locale/fr.json'

export default function useGlossarySSR(lang) {
  let glossary = {}

  switch (lang) {
    case 'es':
      glossary = esGlossary
      break
    case 'en':
      glossary = enGlossary
      break
    case 'pt':
      glossary = ptGlossary
      break
    case 'fr':
      glossary = frGlossary
      break
    default:
      return baseConfig.defaultLocale
  }

  const getNestedTranslations = (object = {}, words = []) => {
    const currentWord = words.shift()

    if (words.length > 0) return getNestedTranslations(object[currentWord], words)

    return object[currentWord]
  }

  const glossarySSR = (label, replace = {}) => {
    let localeString = getNestedTranslations(glossary, label.split('.'))
    // let localeString = glossary[label]
    if (!localeString) return
    for (const clave in replace)
      if (replace.hasOwnProperty(clave)) {
        const regex = new RegExp('\\*\\|' + clave + '\\|\\*', 'g')
        localeString = localeString.replace(regex, replace[clave])
      }
    return localeString
  }

  return {
    glossarySSR,
  }
}
